import React from "react";

export default function MobileFAQNav({ openMenu }) {
  return (
    <div className="d-flex align-items-center justify-content-between d-md-none">
      <button
        className="page-btn"
        type="button"
        onClick="window.history.back()"
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.52837 8L6.47168 7.05666L3.41502 4L6.47168 0.943344L5.52837 0L1.52837 4L5.52837 8Z"
            fill="#005EEA"
          />
        </svg>
        <span>Назад</span>
      </button>
      <button
        className="page-btn menu-btn"
        type="button"
        onClick={() => openMenu(true)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12H20M4 8H20M4 16H12"
              stroke="#005EEA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
        <span>Меню</span>
      </button>
    </div>
  );
}
