import KakZaytiVServisFAQPage from "@View/pages/AdminPO/faq/pages/KakZaytiVServisPageFAQ";
import ChtoDelatEsliZabyliParolFAQPage from "@View/pages/AdminPO/faq/pages/ChtoDelatEsliZabyliParolPageFAQ";
import KakRabotatVSistemeSmartanKratkayaShemaFAQPage from "@View/pages/AdminPO/faq/pages/KakRabotatVSistemeSmartanKratkayaShemaPageFAQ";
import KakDobavitNovogoKlientaFAQPage from "@View/pages/AdminPO/faq/pages/KakDobavitNovogoKlientaPageFAQ";
import KlientyZaprosyPolzovateliOpredeleniyaFAQPage from "@View/pages/AdminPO/faq/pages/KlientyZaprosyPolzovateliOpredeleniyaPageFAQ";
import KakSozdatNovyiZaprosIOtpravitEgoFAQPage from "@View/pages/AdminPO/faq/pages/KakSozdatNovyiZaprosIOtpravitEgoPageFAQ";
import ChtoPrikhoditKlientuPosleOtpravkiZaprosaFAQPage from "@View/pages/AdminPO/faq/pages/ChtoPrikhoditKlientuPosleOtpravkiZaprosaPageFAQ";
import ChtoZnachitStolbecStatusFAQPage from "@View/pages/AdminPO/faq/pages/ChtoZnachitStolbecStatusPageFAQ";
import GdePosmotretOtchetnostIFinansovyeDannyeFAQPage from "@View/pages/AdminPO/faq/pages/GdePosmotretOtchetnostIFinansovyeDannyePageFAQ";
import KakRasschityvayutsyaPokazateliVSmartanFAQPage from "@View/pages/AdminPO/faq/pages/KakRasschityvayutsyaPokazateliVSmartanPageFAQ";
import KakZadatPeriodFAQPage from "@View/pages/AdminPO/faq/pages/KakZadatPeriodPageFAQ";
import KakVybratChastotuFAQPage from "@View/pages/AdminPO/faq/pages/KakVybratChastotuPageFAQ";
import KakNastroitTipAnalizaFAQPage from "@View/pages/AdminPO/faq/pages/KakNastroitTipAnalizaPageFAQ";
import VygruzkaFinansovoyOtchetnostiFAQPage from "@View/pages/AdminPO/faq/pages/VygruzkaFinansovoyOtchetnostiPageFAQ";
import KakZadatPeriodDolgovayaNagruzkaFAQPage from "@View/pages/AdminPO/faq/pages/KakZadatPeriodDolgovayaNagruzkaPageFAQ";
import KakZadatTipAnalizaDolgovayaNagruzkaFAQPage from "@View/pages/AdminPO/faq/pages/KakZadatTipAnalizaDolgovayaNagruzkaPageFAQ";
import KakZadatPeriodProdazhiFAQPage from "@View/pages/AdminPO/faq/pages/KakZadatPeriodProdazhiPageFAQ";
import VizualizatsiyaVGrafikiDashbordFAQPage from "@View/pages/AdminPO/faq/pages/VizualizatsiyaVGrafikiDashbordPageFAQ";
import KakZadatPeriodKontragentyFAQPage from "@View/pages/AdminPO/faq/pages/KakZadatPeriodKontragentyPageFAQ";
import KakZadatChastotuIPeriodTranzaktsionnyyAnalizFAQPage from "@View/pages/AdminPO/faq/pages/KakZadatChastotuIPeriodTranzaktsionnyyAnalizPageFAQ";
import FiltryTranzaktsionnyyAnalizFAQPage from "@View/pages/AdminPO/faq/pages/FiltryTranzaktsionnyyAnalizPageFAQ";
import VygruzkaOtchetnostiTranzaktsionnyyAnalizFAQPage from "@View/pages/AdminPO/faq/pages/VygruzkaOtchetnostiTranzaktsionnyyAnalizPageFAQ";
import VizualizatsiyaVGrafikiDashbordTranzaktsionnyyAnalizFAQPage from "@View/pages/AdminPO/faq/pages/VizualizatsiyaVGrafikiDashbordTranzaktsionnyyAnalizPageFAQ";
import DataQualityProverkaKachestvaDannykhFAQPage from "@View/pages/AdminPO/faq/pages/DataQualityProverkaKachestvaDannykhPageFAQ";
import VizualizatsiyaVGrafikiDashbordDqFAQPage from "@View/pages/AdminPO/faq/pages/VizualizatsiyaVGrafikiDashbordDqPageFAQ";
import KakKonsolidirovatOtchetnostFAQPage from "@View/pages/AdminPO/faq/pages/KakKonsolidirovatOtchetnostPageFAQ";
import KakUznatKreditnyyReytingKompaniiFAQPage from "@View/pages/AdminPO/faq/pages/KakUznatKreditnyyReytingKompaniiPageFAQ";
import KakNachatRabotuVSistemePageFAQ from "@View/pages/AdminPO/faq/pages/KakNachatRabotuVSistemePageFAQ";

export const FaqRouter = [
  {
    title: "Начало работы",
    subitem: [
      {
        title: "Как начать работу в системе Смартан",
        url: "kak_nachat_rabotu",
        component: <KakNachatRabotuVSistemePageFAQ />,
      },
      {
        title: "Как зайти в сервис?",
        url: "kak_zayti_v_servis",
        component: <KakZaytiVServisFAQPage />,
      },
      {
        title: "Что делать, если забыли пароль?",
        url: "chto_delat_esli_zabyli_parol",
        component: <ChtoDelatEsliZabyliParolFAQPage />,
      },
    ],
  },
  {
    title: "Как работать в системе Смартан: краткая схема",
    url: "kak_rabotat_v_sisteme_smartan_kratkaya_shema",
    component: <KakRabotatVSistemeSmartanKratkayaShemaFAQPage />,
  },
  {
    title: "Как добавить нового клиента?",
    url: "kak_dobavit_novogo_klienta",
    component: <KakDobavitNovogoKlientaFAQPage />,
  },
  {
    title: "Вкладки Клиенты, Запросы, Пользователи: определения",
    url: "klienty_zaprosy_polzovateli_opredeleniya",
    component: <KlientyZaprosyPolzovateliOpredeleniyaFAQPage />,
  },
  {
    title: "Новый запрос",
    subitem: [
      {
        title: "Как создать новый запрос и отправить его?",
        url: "kak_sozdat_novyi_zapros_i_otpravit_ego",
        component: <KakSozdatNovyiZaprosIOtpravitEgoFAQPage />,
      },
      {
        title: "Что приходит клиенту после отправки запроса?",
        url: "chto_prikhodit_klientu_posle_otpravki_zaprosa",
        component: <ChtoPrikhoditKlientuPosleOtpravkiZaprosaFAQPage />,
      },
      {
        title: "Что значит столбец «Статус»?",
        url: "chto_znachit_stolbec_status",
        component: <ChtoZnachitStolbecStatusFAQPage />,
      },
    ],
  },
  {
    title: "Где посмотреть отчётность и финансовые данные?",
    url: "gde_posmotret_otchetnost_i_finansovye_dannye",
    component: <GdePosmotretOtchetnostIFinansovyeDannyeFAQPage />,
  },
  {
    title: "Финансовая отчетность",
    subitem: [
      {
        title: "Как рассчитываются показатели в Смартан?",
        url: "kak_rasschityvayutsya_pokazateli_v_smartan",
        component: <KakRasschityvayutsyaPokazateliVSmartanFAQPage />,
      },
      {
        title: "Как задать период?",
        url: "kak_zadat_period",
        component: <KakZadatPeriodFAQPage />,
      },
      {
        title: "Как выбрать частоту?",
        url: "kak_vybrat_chastotu",
        component: <KakVybratChastotuFAQPage />,
      },
      {
        title: "Как настроить тип анализа?",
        url: "kak_nastroit_tip_analiza",
        component: <KakNastroitTipAnalizaFAQPage />,
      },
      {
        title: "Выгрузка финансовой отчетности",
        url: "vygruzka_finansovoy_otchetnosti",
        component: <VygruzkaFinansovoyOtchetnostiFAQPage />,
      },
    ],
  },
  {
    title: "Долговая нагрузка",
    subitem: [
      {
        title: "Как задать период?",
        url: "kak_zadat_period_dolgovaya_nagruzka",
        component: <KakZadatPeriodDolgovayaNagruzkaFAQPage />,
      },
      {
        title: "Как задать тип анализа?",
        url: "kak_zadat_tip_analiza_dolgovaya_nagruzka",
        component: <KakZadatTipAnalizaDolgovayaNagruzkaFAQPage />,
      },
    ],
  },
  {
    title: "Продажи",
    subitem: [
      {
        title: "Как задать период?",
        url: "kak_zadat_period_prodazhi",
        component: <KakZadatPeriodProdazhiFAQPage />,
      },
      {
        title: "Визуализация в графики: дашборд",
        url: "vizualizatsiya_v_grafiki_dashbord",
        component: <VizualizatsiyaVGrafikiDashbordFAQPage />,
      },
    ],
  },
  {
    title: "Контрагенты",
    subitem: [
      {
        title: "Как задать период?",
        url: "kak_zadat_period_kontragenty",
        component: <KakZadatPeriodKontragentyFAQPage />,
      },
    ],
  },
  {
    title: "Транзакционный анализ",
    subitem: [
      {
        title: "Как задать частоту и период?",
        url: "kak_zadat_chastotu_i_period_tranzaktsionnyy_analiz",
        component: <KakZadatChastotuIPeriodTranzaktsionnyyAnalizFAQPage />,
      },
      {
        title: "Фильтры",
        url: "filtry_tranzaktsionnyy_analiz",
        component: <FiltryTranzaktsionnyyAnalizFAQPage />,
      },
      {
        title: "Выгрузка отчетности",
        url: "vygruzka_otchetnosti_tranzaktsionnyy_analiz",
        component: <VygruzkaOtchetnostiTranzaktsionnyyAnalizFAQPage />,
      },
      {
        title: "Визуализация в графики: дашборд",
        url: "vizualizatsiya_v_grafiki_dashbord_tranzaktsionnyy_analiz",
        component: (
          <VizualizatsiyaVGrafikiDashbordTranzaktsionnyyAnalizFAQPage />
        ),
      },
    ],
  },
  {
    title: "Раздел Dq(Data quality): проверка качества данных",
    subitem: [
      {
        title: "Что такое Dq",
        url: "data_quality_proverka_kachestva_dannykh",
        component: <DataQualityProverkaKachestvaDannykhFAQPage />,
      },
      {
        title: "Визуализация в графики: дашборд",
        url: "vizualizatsiya_v_grafiki_dashbord_dq",
        component: <VizualizatsiyaVGrafikiDashbordDqFAQPage />,
      },
    ],
  },
  {
    title: "Как консолидировать отчётность?",
    url: "kak_konsolidirovat_otchetnost",
    component: <KakKonsolidirovatOtchetnostFAQPage />,
  },
  {
    title: "Как узнать кредитный рейтинг компании?",
    url: "kak_uznat_kreditnyy_reyting_kompanii",
    component: <KakUznatKreditnyyReytingKompaniiFAQPage />,
  },
];
