import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useEffect } from "react";
import CheckPointIcon from "@View/components/svgComponents/CheckPointIcon";
import EmptyPointIcon from "@View/components/svgComponents/EmptyPointIcon";
import AppAPI from "@API/index";
import { formatDate } from "@Localization/utils";
import { useTranslation } from "react-i18next";
import { useButtonStyles } from "@View/pages/AdminPO/customer/customerDetails/bankStatement/styles";
import { useHistory } from "react-router-dom";

const firstColumnStyles = {
  whiteSpace: "nowrap",
  paddingRight: "15px",
  position: "sticky",
  left: 0,
  background: "#fff",
};

function sortTableData([i], [k]) {
  if (i.startsWith("00") && k.startsWith("00")) {
    return +i - +k;
  } else if (i.startsWith("00") || k.startsWith("00")) {
    return i.startsWith("00") ? 1 : -1;
  }
  return +i - +k;
}

const FinDataRequestModal = ({
  openRequest,
  handleOpen,
  handleClose,
  addClientFinData = null,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(null);
  const btnClasses = useButtonStyles();

  const fetch = async () => {
    setIsLoading(true);
    const data = await AppAPI.request({
      url: `/api/FinDataRequest/${openRequest.id}?fullInfo=true`,
    });
    if (data.finDataSummaryDetails) {
      setTableData(data.finDataSummaryDetails);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (openRequest) fetch();
  }, [openRequest]);

  const history = useHistory();
  const openFinDataRequest = () => {
    handleClose();
    history.push(`/findatarequest/${openRequest.id}/data/`);
  };

  const addFinData = () => {
    if (typeof addClientFinData === "function") {
      handleClose();
      addClientFinData();
    } else {
      history.push(`/client/details/${openRequest.clientId}`, {
        addFinDataOpen: true,
      });
    }
  };

  return (
    <ModalWrapper
      open={Boolean(openRequest)}
      handleOpen={handleOpen}
      handleClose={handleClose}
      maxWidth={720}
      maxHeight={"100%"}
    >
      <div style={{ margin: "auto", maxWidth: "100%", position: "relative" }}>
        <ModalTittle>FinReport.title</ModalTittle>
        {isLoading || !tableData ? (
          <div style={{ textAlign: "center", padding: "40px 0" }}>
            {t(isLoading ? "creditScore.loading" : "FinReport.noData")}
          </div>
        ) : (
          <>
            <div
              style={{
                textAlign: "center",
                fontSize: 15,
                padding: "0px 0 15px 0",
              }}
            >
              {t("FinReport.modalTitle")}
            </div>
            <div style={{ overflow: "auto", maxHeight: "50vh" }}>
              <div
                className="fin-table"
                style={{
                  gridTemplateColumns: `auto repeat(${tableData.dates.length}, 1fr)`,
                }}
              >
                <div style={firstColumnStyles}>{t("FinReport.docName")}</div>
                {tableData.dates.map((date) => (
                  <div>{formatDate(date)}</div>
                ))}
                <div style={firstColumnStyles}>
                  {t("attach.balanceSheetUndOfr")}
                </div>
                {tableData.dates.map((columnDate) =>
                  tableData.balancesAndFinResultsDates.includes(columnDate) ? (
                    <CheckPointIcon />
                  ) : (
                    <EmptyPointIcon />
                  )
                )}
                {Object.entries(tableData.accountsDates)
                  .sort(sortTableData)
                  .map(([rowName, rowValues = []]) => {
                    return (
                      <>
                        <div style={firstColumnStyles}>{rowName}</div>
                        {tableData.dates.map((columnDate) =>
                          rowValues.includes(columnDate) ? (
                            <CheckPointIcon />
                          ) : (
                            <EmptyPointIcon />
                          )
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        {!isLoading ? (
          <>
            <div style={{ paddingTop: "15px" }}>
              <button
                className={btnClasses.addBtn}
                onClick={() => openFinDataRequest()}
              >
                {t("attach.lookRequest")}
              </button>
            </div>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <span
                style={{
                  cursor: "pointer",
                  color: "rgba(111, 164, 242, 1)",
                  fontWeight: "600",
                  fontSize: "15px",
                  textDecoration: "none",
                }}
                onClick={() => addFinData()}
              >
                {t("attach.attachNewFiles")}
              </span>
            </div>
          </>
        ) : null}
      </div>
    </ModalWrapper>
  );
};

export default FinDataRequestModal;
