import React from "react";

export default function ChtoPrikhoditKlientuPosleOtpravkiZaprosaFAQPage() {
  return (
    <article>
      <h1>Что приходит клиенту после отправки запроса?</h1>
      <br />
      <p>
        При каждом запросе, клиенту приходит письмо на указанную при добавлении
        в системе почту бухгалтера. В письме он получит ссылку на запрос и
        инструкцию по работе с его бухгалтерской системой.
      </p>
      <p>
        <i>
          <b>Процесс отправки данных займет у клиента не более 10 минут.</b>
        </i>
      </p>
      <p>
        Создавать новый запрос нужно каждый раз при запросе данных от клиента.
      </p>
      <p>
        <i>
          <b>Вы можете создавать и удалять запросы.</b>
        </i>
      </p>
      <p>Нажмите «Создать новый» на странице запроса или «Удалить» в строке.</p>
      <p>
        <i>
          <b>Вы можете увидеть детали вашего запроса</b>
        </i>
      </p>
      <p>В разделе «Запросы», нажмите на кнопку «Детали».</p>
      <p>
        <i>
          <b>Вы можете просмотреть отчёт или выгрузить в Excel</b>
        </i>
      </p>
      <p>
        В разделе «Запросы» нажмите на кнопку «Просмотреть отчёт» и откроется
        новая страница с отчетностью в виде дашбордов и графиков.
      </p>
    </article>
  );
}
