import React from "react";

const FinDetailsIcon = () => {
  return (
    <div style={{ display: "flex" }}>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6666 13V6.71467C19.6565 3.71093 17.3298 1.22422 14.3333 1.01468C14.2578 1.0049 14.1818 1.00001 14.1053 1.00001H6.74399C3.58068 0.994108 1.01029 3.55137 1 6.71467V15.2853C1.01029 18.4486 3.58068 21.0059 6.74399 21H12"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.6669 7.4644C20.0811 7.4644 20.4169 7.12861 20.4169 6.7144C20.4169 6.30019 20.0811 5.9644 19.6669 5.9644V7.4644ZM14.3336 6.7144H13.5836C13.5836 7.12861 13.9194 7.4644 14.3336 7.4644V6.7144ZM15.0836 1.0144C15.0836 0.600191 14.7478 0.264404 14.3336 0.264404C13.9194 0.264404 13.5836 0.600191 13.5836 1.0144H15.0836ZM12 12.4162C12.4142 12.4162 12.75 12.0804 12.75 11.6662C12.75 11.252 12.4142 10.9162 12 10.9162V12.4162ZM5 10.9162C4.58579 10.9162 4.25 11.252 4.25 11.6662C4.25 12.0804 4.58579 12.4162 5 12.4162V10.9162ZM9 8.41622C9.41421 8.41622 9.75 8.08043 9.75 7.66622C9.75 7.25201 9.41421 6.91622 9 6.91622V8.41622ZM5 6.91622C4.58579 6.91622 4.25 7.25201 4.25 7.66622C4.25 8.08043 4.58579 8.41622 5 8.41622V6.91622ZM12 16.4162C12.4142 16.4162 12.75 16.0804 12.75 15.6662C12.75 15.252 12.4142 14.9162 12 14.9162V16.4162ZM5 14.9162C4.58579 14.9162 4.25 15.252 4.25 15.6662C4.25 16.0804 4.58579 16.4162 5 16.4162V14.9162ZM19.6669 5.9644H14.3336V7.4644H19.6669V5.9644ZM15.0836 6.7144V1.0144H13.5836V6.7144H15.0836ZM12 10.9162H5V12.4162H12V10.9162ZM9 6.91622H5V8.41622H9V6.91622ZM12 14.9162H5V16.4162H12V14.9162Z"
          fill="currentColor"
        />
        <path
          d="M15.0303 17.4697C14.7374 17.1768 14.2626 17.1768 13.9697 17.4697C13.6768 17.7626 13.6768 18.2374 13.9697 18.5303L15.0303 17.4697ZM15.5 19L14.9697 19.5303C15.2626 19.8232 15.7374 19.8232 16.0303 19.5303L15.5 19ZM19.0303 16.5303C19.3232 16.2374 19.3232 15.7626 19.0303 15.4697C18.7374 15.1768 18.2626 15.1768 17.9697 15.4697L19.0303 16.5303ZM13.9697 18.5303L14.9697 19.5303L16.0303 18.4697L15.0303 17.4697L13.9697 18.5303ZM16.0303 19.5303L19.0303 16.5303L17.9697 15.4697L14.9697 18.4697L16.0303 19.5303Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default FinDetailsIcon;
