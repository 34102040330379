import React from "react";

export default function KlientyZaprosyPolzovateliOpredeleniyaFAQPage() {
  return (
    <article>
      <h1>Клиенты, Запросы, Пользователи: определения</h1>
      <br />
      <p>
        <strong>Клиент</strong> - компания, у которой запрашиваются данные.
      </p>
      <img src="img/img8.png" alt="" />
      <p>
        <strong>Запросы</strong> - отправленные Компанией данные отображаются в
        этой вкладке.
      </p>
      <img src="img/img9.png" alt="" />
      <p>
        <strong>Пользователи</strong> - компания, которая запрашивает данные.
        Это Вы. Пользователей от Вашей компании может быть несколько.
      </p>
      <img src="img/img10.png" alt="" />
    </article>
  );
}
