import React from "react";

export default function KakZadatChastotuIPeriodTranzaktsionnyyAnalizFAQPage() {
  return (
    <article>
      <h1>Как задать частоту и период?</h1>
      <br />
      <p>Здесь можно выбрать частоту и период анализа</p>
      <img src="img/img26.png" alt="" />
    </article>
  );
}
