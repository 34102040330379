import React from "react";

export default function KakRasschityvayutsyaPokazateliVSmartanFAQPage() {
  return (
    <article>
      <h1>Где посмотреть отчётность и финансовые данные?</h1>
      <br />
      <img src="img/img16.png" alt="" />
    </article>
  );
}
