import React from "react";

export default function KakZaytiVServisFAQPage() {
  return (
    <article>
      <h1>Как зайти в сервис?</h1>
      <p>Чтобы повторно зайти в сервис, воспользуйтесь ссылкой из письма</p>
      <img src="img/img4.png" alt="" />
    </article>
  );
}
