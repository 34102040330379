import React from "react";

export default function VygruzkaOtchetnostiTranzaktsionnyyAnalizFAQPage() {
  return (
    <article>
      <h1>Выгрузка отчетности</h1>
      <br />
      <p>Чтобы выгрузить отчетность, нажмите на кнопку Экспорт</p>
      <img src="img/img28.png" alt="" />
    </article>
  );
}
