import React from "react";

export default function KakNastroitTipAnalizaFAQPage() {
  return (
    <article>
      <h1>Как настроить тип анализа?</h1>
      <br />
      <p>
        Выбирайте подходящий для вас тип анализа: вертикальный или
        коэффициентный
      </p>
      <img src="img/img19.png" alt="" />
    </article>
  );
}
