import React from "react";

export default function VizualizatsiyaVGrafikiDashbordFAQPage() {
  return (
    <article>
      <h1>Как задать период?</h1>
      <br />
      <p>Здесь можно выбрать период анализа</p>
      <p>Минимальный шаг: 1 месяц</p>
      <p>Выбранный период отразится в этом поле</p>
      <img src="img/img23.png" alt="" />
    </article>
  );
}
