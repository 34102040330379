import React from "react";

export default function FiltryTranzaktsionnyyAnalizFAQPage() {
  return (
    <article>
      <h1>Фильтры</h1>
      <br />
      <img src="img/img27.png" alt="" />
    </article>
  );
}
