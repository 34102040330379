import React from "react";

export default function GdePosmotretOtchetnostIFinansovyeDannyeFAQPage() {
  return (
    <article>
      <h1>Где посмотреть отчётность и финансовые данные?</h1>
      <br />
      <p>Нажмите на крайний правый знак в разделе «Запросы».</p>
      <img src="img/img14.png" alt="" />
      <p>После нажатия открывается раздел финансовой отчётности</p>
      <p>
        <strong>
          <em>Настраивайте периоды и тип анализа</em>
        </strong>
      </p>
      <p>
        Переключайте периоды анализа в рамках периода запроса, а также типы
        анализа.
      </p>
      <p>
        <strong>
          <em>Данные доступны в виде дашбордов и таблиц</em>
        </strong>
      </p>
      <p>
        В этом разделе доступны: финансовая отчетность, долговая нагрузка,
        продажи, контрагенты, транзакционный анализ.
      </p>
      <p>
        <strong>
          <em>На каждой странице доступна выгрузка в Excel</em>
        </strong>
      </p>
      <p>Так выглядит экран после нажатия кнопки</p>
      <img src="img/img15.png" alt="" />
    </article>
  );
}
