import React from "react";

export default function KakDobavitNovogoKlientaFAQPage() {
  return (
    <article>
      <h1>Как добавить нового клиента?</h1>
      <p>
        <li>На вкладке «Клиенты» нажмите «Добавить новый»</li>
      </p>
      <img src="img/img1.png" alt="" />
      <p>
        <li>
          Заполните данные (емейл бухгалтера клиента; БИК/ИНН) и нажмите
          «Сохранить».
        </li>
      </p>
      <img src="img/img2.png" alt="" />
    </article>
  );
}
