import React from "react";

export default function VygruzkaFinansovoyOtchetnostiFAQPage() {
  return (
    <article>
      <h1>Выгрузка финансовой отчетности</h1>
      <br />
      <p>
        Чтобы выгрузить отчетность, нажмите на кнопку с названием нужного
        формата
      </p>
      <img src="img/img20.png" alt="" />
    </article>
  );
}
