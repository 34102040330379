import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import FAQNav from "@View/pages/AdminPO/faq/components/FaqNav";
import { FaqRouter } from "@View/pages/AdminPO/faq/FaqRouter";
import IndexFAQPage from "@View/pages/AdminPO/faq/pages/IndexPageFAQ";
import MobileFAQNav from "@View/pages/AdminPO/faq/components/MobileFaqNav";

function DrawFAQRouter(routerTree) {
  return routerTree
    .map(({ subitem, url, component }) => {
      if (subitem) return DrawFAQRouter(subitem);
      return (
        <Route exact path={`/faq/${url}`}>
          {component}
        </Route>
      );
    })
    .flat();
}

export default function FAQPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="faq-page">
      <div className="wrapper-content flex-grow-1">
        <div className="container-fluid h-sm-100">
          <BrowserRouter>
            <div className="row h-sm-100">
              <div className="col-md-auto h-sm-100">
                <nav className={"nav" + (isMenuOpen ? " active" : "")}>
                  <FAQNav routerTree={FaqRouter} />
                  <div
                    className="nav-close"
                    onClick={() => setIsMenuOpen(false)}
                  ></div>
                </nav>
              </div>
              <div className="col">
                <main>
                  <MobileFAQNav openMenu={setIsMenuOpen} />
                  <Switch>
                    {DrawFAQRouter(FaqRouter)}
                    <Route>
                      <IndexFAQPage />
                    </Route>
                  </Switch>
                </main>
              </div>
            </div>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}
