import React from "react";

export default function IndexFAQPage() {
  return (
    <article>
      <h1>Как начать работу в системе Смартан</h1>
      <p>
        После оплаты и заключения договора, на вашу электронную почту придет
        письмо со ссылкой на сервис и ссылкой на установку пароля. Перейдите по
        ссылке:
      </p>
      <img src="/faq/img/img.jpg" alt="" />
    </article>
  );
}
