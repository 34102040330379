import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "@View/components/DeleteModal";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteFinDataRequest } from "@State/index";
import DataTable from "@View/components/table/DataTable";
import {
  downloadFileWithNotification,
  downloadSheetWithNotification,
} from "@State/effects";
import { formatDate } from "@Localization/utils";
import { Link, useHistory } from "react-router-dom";
import { Grid, Tooltip } from "@material-ui/core";
import DetailsIcon from "@View/components/svgComponents/DetailsIcon";
import DeleteIcon from "@View/components/svgComponents/DeleteIcon";
import {
  requestCloseDetailsAction,
  requestOpenDetailsAction,
} from "@State/actions/actionsGet/actionGetFinDataRequest";
import DownloadIcon from "@View/components/svgComponents/DownloadIcon";
import ViewIcon from "@View/components/svgComponents/ViewIcon";
import { downloadFile } from "@Dao/api";
import ButtonBB from "@View/components/buttons/ButtonBB";
import ButtonAddNew from "@View/components/buttons/ButtonAddNew";
import NewRequestPage from "@View/pages/AdminPO/request/NewRequestPage";
import RequestDetailsPage from "@View/pages/AdminPO/request/RequestDetailsPage";
import { useTooltipStyle } from "@View/style/components/tooltipStyles";
import { compareDatesForSorting } from "@Utils/dateActions";
import {
  canCreateRequest,
  canEditEverything,
  getRole,
} from "@Security/stateHelpers";
import FinDetailsIcon from "@View/components/svgComponents/FinDetailsIcon";
import FinDataRequestModal from "@View/pages/AdminPO/request/FinDataRequestModal";

//redux
const mapStateToProps = (state) => {
  return {
    finDataRequests: state.customer?.customer?.finDataRequests ?? [],
    openDetails: state.finDataRequest.general.openDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFinDataRequest: (finDataRequestId) =>
      dispatch(deleteFinDataRequest(finDataRequestId)),
  };
};

const TableComponentGlobals = {
  shouldRowHandleClick: true,
};

const RequestsTable = ({
  finDataRequests,
  deleteFinDataRequest,
  openDetails,
  customerId,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [deletedId, setDeletedId] = useState();
  const [requestId, setRequestId] = useState();
  const [isFinModalOpen, setIsFinModalOpen] = useState(null);
  const tooltipStyle = useTooltipStyle();

  const handleOpenModal = (event, finDataRequestId) => {
    event.preventDefault();
    setDeletedId(finDataRequestId);
  };
  let checkboxes = useSelector((state) => state.snackbar.checkboxes);

  const consolidationFunc = async () => {
    let str = checkboxes.join("&requestIds=");
    await downloadFile(
      `/api/FinDataRequest/consolidation/file?requestIds=${str}`
    );
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const requestOpenDetails = (id) => {
    setRequestId(id);
    dispatch(requestOpenDetailsAction());
  };

  const requestCloseDetails = () => {
    dispatch(requestCloseDetailsAction());
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteFinDataRequest(deletedId);
    setDeletedId();
  };

  const handleDownload = (event, fileId, transferType, finDataRequestId) => {
    event.preventDefault();
    if (transferType === "File") {
      downloadFileWithNotification(fileId.finDatafile.id);
    }
    if (transferType === "Json") {
      downloadSheetWithNotification(finDataRequestId);
    }
  };

  const [openRequest, setOpenRequest] = React.useState(false);

  const handleOpenRequest = () => {
    setOpenRequest(true);
  };

  const handleCloseRequest = () => {
    setOpenRequest(false);
  };

  const headTableRequests = [
    {
      field: "clientName",
      headerName: t("Client"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "clientExternalId",
      headerName: t("Client External Id"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "startDate",
      headerName: t("inputs.Start Date"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => formatDate(cell.value),
      sortComparator: compareDatesForSorting,
    },
    {
      field: "endDate",
      headerName: t("inputs.End Date"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => formatDate(cell.value),
      sortComparator: compareDatesForSorting,
    },
    {
      field: "updatedAt",
      headerName: t("Last Update"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => formatDate(cell.value),
      sortComparator: compareDatesForSorting,
    },
    {
      field: "status",
      headerName: t("inputs.Status"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => t(cell.row.status),
    },
    {
      field: "reportType",
      headerName: t("inputs.Type"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => t(cell.row.reportType),
    },
    {
      field: "actions",
      headerName: " ",
      flex: 1,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      renderCell: (cell) => {
        const fileId = cell.row;
        const transferType = cell.row.transferType;
        const status = cell.row.status;

        return (
          <div className="tableIcons">
            <Tooltip title={t("buttons.finReport")} classes={tooltipStyle}>
              <div
                onClick={() => {
                  TableComponentGlobals.shouldRowHandleClick = false;
                  setIsFinModalOpen(cell.row);
                }}
                className={tooltipStyle.element}
              >
                <FinDetailsIcon />
              </div>
            </Tooltip>
            <Tooltip title={t("buttons.Details")} classes={tooltipStyle}>
              <div
                onClick={() => {
                  TableComponentGlobals.shouldRowHandleClick = false;
                  requestOpenDetails(cell.row.id);
                }}
                className={tooltipStyle.element}
              >
                <DetailsIcon />
              </div>
            </Tooltip>

            {canEditEverything() && (
              <Tooltip title={t("buttons.Delete")} classes={tooltipStyle}>
                <div
                  onClick={(e) => {
                    TableComponentGlobals.shouldRowHandleClick = false;
                    handleOpenModal(e, cell.row.id);
                  }}
                  className={tooltipStyle.element}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title={t("buttons.Get data")} classes={tooltipStyle}>
              <div
                onClick={(e) => {
                  TableComponentGlobals.shouldRowHandleClick = false;
                  handleDownload(e, fileId, transferType, cell.row.id);
                }}
                className={[
                  tooltipStyle.element,
                  status === "SentByClient" ? "" : tooltipStyle.inactive,
                ]}
              >
                <DownloadIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.View data")} classes={tooltipStyle}>
              <Link
                to={`/findatarequest/${cell.row.id}/data/`}
                className={[
                  tooltipStyle.element,
                  transferType === "Json" && status === "SentByClient"
                    ? ""
                    : tooltipStyle.inactive,
                ]}
              >
                <ViewIcon />
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          right: "210px",
          top: "-49px",
          zIndex: 2,
        }}
      >
        {checkboxes.length > 1 ? (
          <ButtonBB onClick={consolidationFunc}>{t("Consolidation")}</ButtonBB>
        ) : null}
      </div>
      <NewRequestPage
        clientId={undefined}
        customerId={customerId}
        open={openRequest}
        handleOpen={handleOpenRequest}
        handleClose={handleCloseRequest}
      />

      <RequestDetailsPage
        requestId={requestId}
        open={openDetails}
        handleOpen={requestOpenDetails}
        handleClose={requestCloseDetails}
      />
      <FinDataRequestModal
        openRequest={isFinModalOpen}
        handleOpen={() => {}}
        handleClose={() => setIsFinModalOpen(null)}
      />

      <DataTable
        checkboxSelection={true}
        rows={finDataRequests}
        columns={headTableRequests}
        onRowClick={(event) => {
          setTimeout(() => {
            if (TableComponentGlobals.shouldRowHandleClick) {
              if (!event?.row) {
                return;
              }
              const row = event.row;
              const transferType = row.transferType;
              const status = row.status;
              if (transferType === "Json" && status === "SentByClient") {
                history.push(`/findatarequest/${row.id}/data/`);
              } else {
                requestOpenDetails(row.id);
              }
            } else {
              TableComponentGlobals.shouldRowHandleClick = true;
            }
          });
        }}
        toolbarButtons={
          canCreateRequest() && <ButtonAddNew onClick={handleOpenRequest} />
        }
      />
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
    </React.Fragment>
  );
};

RequestsTable.propTypes = {
  finDataRequests: PropTypes.array.isRequired,
  deleteFinDataRequest: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
