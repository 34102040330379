import React from "react";

export default function KakVybratChastotuFAQPage() {
  return (
    <article>
      <h1>Как выбрать частоту?</h1>
      <br />
      <p>Здесь можно выбрать частоту анализа</p>
      <img src="img/img18.png" alt="" />
    </article>
  );
}
