import React from "react";

export default function KakZadatPeriodDolgovayaNagruzkaFAQPage() {
  return (
    <article>
      <h1>Как задать период?</h1>
      <br />
      <p>Здесь можно выбрать период анализа</p>
      <img src="img/img21.png" alt="" />
    </article>
  );
}
