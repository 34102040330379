import React from "react";

export default function KakKonsolidirovatOtchetnostFAQPage() {
  return (
    <article>
      <h1>Как консолидировать отчётность?</h1>
      <br />
      <p>
        На вкладке «Запросы» выделите галочкой два и более запроса. У вас
        появится кнопка «Консолидация». Нажмите на нее. Готово!
      </p>
      <img src="img/img32.png" alt="" />
    </article>
  );
}
