import React from "react";

export default function DataQualityProverkaKachestvaDannykhFAQPage() {
  return (
    <article>
      <h1>Dq (Data quality): проверка качества данных</h1>
      <br />
      <p>
        В разделе DQ вы можете помесячно увидеть информацию по качеству
        сформированной отчетности, ежемесячно сверяются показатели и отклонение
        от целевых показателей.
      </p>
      <img src="img/img30.png" alt="" />
    </article>
  );
}
