import React from "react";

export default function VizualizatsiyaVGrafikiDashbordDqFAQPage() {
  return (
    <article>
      <h1>Визуализация в графики: дашборд</h1>
      <br />
      <p>Нажмите на кнопку «Дашборд» и данные преобразуются в графики</p>
      <img src="img/img31.png" alt="" />
    </article>
  );
}
