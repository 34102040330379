import React from "react";

const EmptyPointIcon = () => {
  return (
    <div style={{ display: "flex" }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4415 14.3608C15.6856 14.5943 15.6856 14.973 15.4415 15.2065C15.3198 15.3229 15.1598 15.3819 14.9998 15.3819C14.8398 15.3819 14.6798 15.3237 14.5581 15.2065L9.99982 10.8464L5.44146 15.2065C5.31979 15.3229 5.15979 15.3819 4.99979 15.3819C4.83979 15.3819 4.67979 15.3237 4.55813 15.2065C4.31396 14.973 4.31396 14.5943 4.55813 14.3608L9.11646 10.0007L4.55813 5.64054C4.31396 5.407 4.31396 5.02834 4.55813 4.7948C4.80229 4.56124 5.19813 4.56124 5.4423 4.7948L10.0006 9.15494L14.5589 4.7948C14.8031 4.56124 15.199 4.56124 15.4431 4.7948C15.6873 5.02834 15.6873 5.407 15.4431 5.64054L10.8848 10.0007L15.4415 14.3608Z"
          fill="#A4241C"
        />
      </svg>
    </div>
  );
};

export default EmptyPointIcon;
