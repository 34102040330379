import React from "react";

export default function KakUznatKreditnyyReytingKompaniiFAQPage() {
  return (
    <article>
      <h1>Как узнать кредитный рейтинг компании?</h1>
      <br />
      <p>
        На вкладке «Клиенты» есть столбец «Кредитный рейтинг». Чтобы посмотреть
        кредитное заключение, нажмите на круг:
      </p>
      <img src="img/img33.png" alt="" />
      <p>Полное кредитное заключение по клиенту выглядит так:</p>
      <img src="img/img34.png" alt="" />
    </article>
  );
}
