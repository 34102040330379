import React from "react";

export default function KakZadatTipAnalizaDolgovayaNagruzkaFAQPage() {
  return (
    <article>
      <h1>Как задать тип анализа?</h1>
      <br />
      <p>
        Выбирайте подходящий разрез информации:
        <li>горизонтальный (с наименованием кредиторов)</li>
        <li>
          вертикальный (с наименованием кредиторов и долей задолженности перед
          каждым).
        </li>
        <p>Минимальный шаг: 1 месяц</p>
        <img src="img/img22.png" alt="" />
      </p>
    </article>
  );
}
