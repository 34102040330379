import { makeStyles } from "@material-ui/core";
import { Snackbar } from "@State/actions/actionCreators";
import ButtonBB from "@View/components/buttons/ButtonBB";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AppAPI from "@API/index";
import ModalTittle from "@View/components/ModalTittle";
import { downloadFinDataV1TemplateFileFunc } from "@Utils/downloadLinks";
import CommonError from "@View/pages/AdminPO/customer/customerDetails/bankStatement/CommonError";
import { useFileLoader } from "@Utils/useFileLoader";
import CommonLoader from "@View/components/ui/CommonLoader";

const UploadFileModal = ({
  open,
  handleOpen,
  handleClose,
  openBankStatements,
  openBankStatements1C,
  client,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const openBankStatementsLocal = () => {
    handleClose();
    openBankStatements();
  };

  const { openChooseFileWindow } = useFileLoader();
  const uploadStartFile = async () => {
    openChooseFileWindow({}, async (files) => {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("clientId", client.id);
      let res;
      try {
        res = await AppAPI.request({
          url: "/api/FinDataRequest/upload-finstatement/xlsx",
          method: "POST",
          data: formData,
        });
      } catch (e) {}

      if (res?.requestId) {
        history.push(`/findatarequest/${res.requestId}/data`);
        return;
      }

      if (typeof res === "string") {
        dispatch(Snackbar({ message: "snackbar.success", open: true }));
      }
    });
  };

  const loadBankStatements1C = () => {
    openChooseFileWindow({ accept: ".txt" }, async (files) => {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("clientId", client.id);

      try {
        await AppAPI.request({
          url: "/api/FinDataRequest/upload-transactions/txt",
          method: "POST",
          data: formData,
        });
        handleClose();
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    });
  };

  const bankStatements1CLocalAction = () => {
    if (
      Array.isArray(client?.finDataRequests) &&
      client?.finDataRequests.length
    ) {
      handleClose();
      openBankStatements1C();
    } else {
      loadBankStatements1C();
    }
  };

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <ModalTittle>buttons.Upload Financial Data</ModalTittle>
      {isError ? (
        <CommonError closeError={() => setIsError(false)} />
      ) : isLoading ? (
        <CommonLoader />
      ) : (
        <>
          <div style={{ marginBottom: "30px", marginTop: "5px" }}>
            <ButtonBB
              style={{ width: "100%" }}
              fullWidth
              onClick={() => uploadStartFile()}
            >
              {t("buttons.UploadStartFile")}
            </ButtonBB>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <a
                href="#"
                style={{ width: "100%" }}
                onClick={() => downloadFinDataV1TemplateFileFunc()}
              >
                {t("buttons.fileExample")}
              </a>
            </div>
          </div>
          <ButtonBB
            style={{ width: "100%", marginBottom: "15px" }}
            fullWidth
            onClick={() => bankStatements1CLocalAction()}
          >
            {t("buttons.bankStatements1C")}
          </ButtonBB>
          <ButtonBB
            style={{ width: "100%", marginBottom: "15px" }}
            fullWidth
            onClick={() => openBankStatementsLocal()}
          >
            {t("finData1C.title")}
          </ButtonBB>
        </>
      )}
    </ModalWrapper>
  );
};

export default UploadFileModal;
