import React, { useState } from "react";
import { Link } from "react-router-dom";

function NavSubItem({ title, subitem }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li>
      <h3 className={isOpen ? "active" : ""} onClick={() => setIsOpen(!isOpen)}>
        {title}
      </h3>
      {subitem}
    </li>
  );
}

export default function FAQNav({ routerTree }) {
  return (
    <ul>
      {routerTree.map(({ title, url, subitem }) =>
        subitem ? (
          <NavSubItem title={title} subitem={<FAQNav routerTree={subitem} />} />
        ) : (
          <li>
            <Link to={`/faq/${url}`}>{title}</Link>
          </li>
        )
      )}
    </ul>
  );
}
