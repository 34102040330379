import {
  Container,
  FormControl,
  FormLabel,
  Typography,
} from "@material-ui/core";
import {
  DatePicker,
  DatePickerView,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { useTranslation } from "react-i18next";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

export function RangeDatePicker({
  label,
  startValue,
  endValue,
  views,
  format,
  onChangeStart,
  onChangeEnd,
  vertical,
  flex,
}: RangeDatePickerProps) {
  const { i18n } = useTranslation();

  if (format === undefined) {
    format = i18n.language === "ru" ? "dd.MM.yyyy" : "MM/dd/yyyy";
  }

  const onChangeStartHandler = (date: MaterialUiPickersDate) => {
    if (date) {
      if (flex) {
        onChangeStart(date);
        return;
      }
      const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
      onChangeStart(newDate);
    }
  };
  const onChangeEndHandler = (date: MaterialUiPickersDate) => {
    if (date) {
      if (flex) {
        onChangeEnd(date);
        return;
      }
      const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      onChangeEnd(newDate);
    }
  };

  return (
    <FormControl
      className={"RangeDatePicker"}
      style={
        flex
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : vertical
          ? {}
          : {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }
      }
    >
      {label ? (
        vertical ? (
          <FormLabel>
            <Typography>{label}</Typography>
          </FormLabel>
        ) : (
          <label style={{ minWidth: "200px", maxWidth: "200px" }}>
            <Typography>{label}</Typography>
          </label>
        )
      ) : null}
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={i18n.language === "en" ? enLocale : ruLocale}
      >
        <div
          style={{
            display: "flex",
            border: "1px solid #E1E6F1",
            borderRadius: "8px",
            padding: "14px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#A1ADBA",
                display: "flex",
                alignItems: "center",
              }}
            >
              c&nbsp;
            </div>
            <DatePicker
              size="small"
              views={views}
              autoOk
              variant="inline"
              inputVariant="outlined"
              format={format}
              inputProps={{ readOnly: true }}
              value={startValue}
              onChange={onChangeStartHandler}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#A1ADBA",
              }}
            >
              &nbsp;&nbsp;по&nbsp;
            </div>
            <DatePicker
              views={views}
              autoOk
              variant="inline"
              inputVariant="outlined"
              format={format}
              inputProps={{ readOnly: true }}
              value={endValue}
              onChange={onChangeEndHandler}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

type RangeDatePickerProps = {
  label?: string;
  startValue?: ParsableDate | any;
  endValue?: ParsableDate | any;
  minValue?: ParsableDate;
  maxValue?: ParsableDate;
  format?: string;
  views?: DatePickerView[];
  onChangeStart: (date: MaterialUiPickersDate) => void;
  onChangeEnd: (date: MaterialUiPickersDate) => void;
  vertical?: boolean;
  flex?: boolean;
};
