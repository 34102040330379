import React from "react";

export default function ChtoZnachitStolbecStatusFAQPage() {
  return (
    <article>
      <h1>Что значит столбец «Статус»?</h1>
      <br />
      <p>
        Столбец «Статус» показывает состояние запроса. «Новый» - клиент получил
        запрос, но пока не ответил. «Отправлен клиентом» - данные готовы для
        просмотра.
      </p>
      <img src="img/img13.png" alt="" />
    </article>
  );
}
