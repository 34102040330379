import { downloadFile } from "@Dao/api";

export function getStringDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return [
    String(month).padStart(2, "0"),
    String(day).padStart(2, "0"),
    year,
  ].join(".");
}

export const downloadFile2Func = async (id, startDate, endDate) => {
  await downloadFile(
    `/api/Customer/${id}/risk-signals/portfolio?startDate=${getStringDate(
      startDate
    )}&endDate=${getStringDate(endDate)}`
  );
};

export const downloadFileFunc = async (clientId) => {
  await downloadFile(`/api/Client/${clientId}/risk-signals/form`);
};

export const downloadCreditRatingFile = async (clientId) => {
  await downloadFile(`/api/Client/${clientId}/avers-rating/file`);
};

export const downloadFinDataV1TemplateFileFunc = async () => {
  await downloadFile(`/api/FinDataRequest/upload-finstatement/xlsx/template`);
};

export const downloadFinDataTemplateFileFunc = async (id) => {
  await downloadFile(`/api/Customer/reporttemplate/${id}`);
};
