import React from "react";

export default function ChtoDelatEsliZabyliParolFAQPage() {
  return (
    <article>
      <h1>Что делать, если забыли пароль?</h1>
      <p>
        <li>Нажмите на кнопку: Забыли пароль.</li>
      </p>
      <img src="img/img5.png" alt="" />
      <p>
        <li>Введите свою почту и нажмите: Сбросить пароль.</li>
      </p>
      <img src="img/img6.png" alt="" />
      <p>
        <li>Установите новый пароль.</li>
      </p>
      <img src="img/img7.png" alt="" />
    </article>
  );
}
