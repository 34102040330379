import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "@View/components/DeleteModal";
import { connect, useDispatch } from "react-redux";
import { deleteFinDataRequest } from "@State/";
import DataTable from "@View/components/table/DataTable";
import { usePageRoot } from "@View/hooks/usePageRoot";
import {
  downloadFileWithNotification,
  downloadSheetWithNotification,
} from "@State/effects";
import { formatDate } from "@Localization/utils";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import DetailsIcon from "@View/components/svgComponents/DetailsIcon";
import {
  requestCloseDetailsAction,
  requestOpenDetailsAction,
} from "@State/actions/actionsGet/actionGetFinDataRequest";
import DownloadIcon from "@View/components/svgComponents/DownloadIcon";
import ViewIcon from "@View/components/svgComponents/ViewIcon";
import ButtonAddNew from "@View/components/buttons/ButtonAddNew";
import NewRequestPage from "@View/pages/AdminPO/request/NewRequestPage";
import RequestDetailsPage from "@View/pages/AdminPO/request/RequestDetailsPage";
import { useTooltipStyle } from "@View/style/components/tooltipStyles";
import { canCreateRequest } from "@Security/stateHelpers";
import FinDetailsIcon from "@View/components/svgComponents/FinDetailsIcon";
import FinDataRequestModal from "@View/pages/AdminPO/request/FinDataRequestModal";

//redux
const mapStateToProps = (state) => {
  return {
    finDataRequests: state.client?.client?.finDataRequests ?? [],
    openDetails: state.finDataRequest.general.openDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFinDataRequest: (finDataRequestId) =>
      dispatch(deleteFinDataRequest(finDataRequestId)),
  };
};

const RequestsTable = ({
  finDataRequests,
  deleteFinDataRequest,
  openDetails,
  customerId,
  clientId,
  addClientFinData = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deletedId, setDeletedId] = useState();
  const [requestId, setRequestId] = useState();

  const [isFinModalOpen, setIsFinModalOpen] = useState(null);

  const requestOpenDetails = (id) => {
    setRequestId(id);
    dispatch(requestOpenDetailsAction());
  };

  const requestCloseDetails = () => {
    dispatch(requestCloseDetailsAction());
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteFinDataRequest(deletedId);
    setDeletedId();
  };

  const [openRequest, setOpenRequest] = React.useState(false);

  const handleOpenRequest = () => {
    setOpenRequest(true);
  };

  const handleCloseRequest = () => {
    setOpenRequest(false);
  };

  const handleDownload = (event, fileId, transferType, finDataRequestId) => {
    event.preventDefault();
    if (transferType === "File") {
      downloadFileWithNotification(fileId.finDataFile.id);
    }
    if (transferType === "Json") {
      downloadSheetWithNotification(finDataRequestId);
    }
  };

  const tooltipStyle = useTooltipStyle();

  const headTableRequests = [
    {
      field: "customerName",
      headerName: t("Customer Name"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "startDate",
      headerName: t("inputs.Start Date"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => formatDate(cell.value),
    },
    {
      field: "endDate",
      headerName: t("inputs.End Date"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => formatDate(cell.value),
    },
    {
      field: "updatedAt",
      headerName: t("Last Update"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => formatDate(cell.value),
    },
    {
      field: "status",
      headerName: t("inputs.Status"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => t(cell.row.status),
    },
    {
      field: "actions",
      headerName: " ",
      flex: 1,
      sortable: false,
      filterable: false,
      headerAlign: "left",
      renderCell: (cell) => {
        const status = cell.row.status;
        const fileId = cell.row;
        const transferType = cell.row.transferType;
        return (
          <div className="tableIcons">
            <Tooltip title={t("buttons.finReport")} classes={tooltipStyle}>
              <div
                onClick={() => {
                  setIsFinModalOpen({ ...cell.row, clientId });
                }}
                className={tooltipStyle.element}
              >
                <FinDetailsIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.Details")}>
              <div
                onClick={() => {
                  requestOpenDetails(cell.row.id);
                }}
                className={tooltipStyle.element}
              >
                <DetailsIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.Get data")}>
              <div
                onClick={(event) =>
                  handleDownload(event, fileId, transferType, cell.row.id)
                }
                className={[
                  tooltipStyle.element,
                  status === "SentByClient" ? "" : tooltipStyle.inactive,
                ]}
              >
                <DownloadIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.View data")}>
              <Link
                to={`/findatarequest/${cell.row.id}/data`}
                className={[
                  tooltipStyle.element,
                  transferType === "Json" && status === "SentByClient"
                    ? ""
                    : tooltipStyle.inactive,
                ]}
              >
                <ViewIcon />
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <DataTable
          rows={finDataRequests}
          columns={headTableRequests}
          toolbarButtons={
            canCreateRequest() && <ButtonAddNew onClick={handleOpenRequest} />
          }
        />

        <NewRequestPage
          clientId={clientId}
          customerId={customerId}
          open={openRequest}
          handleOpen={handleOpenRequest}
          handleClose={handleCloseRequest}
        />
        {openDetails ? (
          <RequestDetailsPage
            requestId={requestId}
            open={openDetails}
            handleOpen={requestOpenDetails}
            handleClose={requestCloseDetails}
          />
        ) : null}
        <DeleteModal
          handleDelete={handleDelete}
          openModal={deletedId !== undefined}
          handleCloseModal={handleCloseModal}
        />
        <FinDataRequestModal
          openRequest={isFinModalOpen}
          handleOpen={() => {}}
          handleClose={() => setIsFinModalOpen(null)}
          addClientFinData={addClientFinData}
        />
      </div>
    </React.Fragment>
  );
};

RequestsTable.propTypes = {
  finDataRequests: PropTypes.array.isRequired,
  deleteFinDataRequest: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
