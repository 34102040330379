import React from "react";

export default function KakRabotatVSistemeSmartanKratkayaShemaFAQPage() {
  return (
    <article>
      <h1>Как работать в системе Смартан: краткая схема</h1>
      <p>
        Заводим нового клиента в систему, в ней запрашиваем данные и получаем
        сформированную отчетность для анализа.
        <li>Авторизуйтесь в системе.</li>
        <li>Добавьте нового клиента.</li>
        <li>Заведите новый запрос.</li>
        <li>Нажмите «Скачать» или «Просмотреть отчёт».</li>
      </p>
    </article>
  );
}
