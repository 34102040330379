import React from "react";

export default function KakSozdatNovyiZaprosIOtpravitEgoFAQPage() {
  return (
    <article>
      <h1>Как создать новый запрос и отправить его?</h1>
      <br />
      <p>
        <li>На вкладке «Запросы» нажмите «Добавить новый».</li>
      </p>
      <img src="img/img11.png" alt="" />
      <p>
        <li>
          Заполните данные: клиент; начальная дата; конечная дата; тип отчета и
          формат отчета.
        </li>
      </p>
      <img src="img/img12.png" alt="" />
      <p>
        В формате отчета необходимо указать тип Json. Excel только для случаев,
        когда у клиента нет подключения к интернету. Нажмите кнопку «Отправить».
      </p>
    </article>
  );
}
